@use '@angular/material' as mat;

@include mat.core();

$fabric-primary: mat.define-palette(mat.$brown-palette);
$fabric-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$fabric-warn: mat.define-palette(mat.$red-palette);
$fabric-theme: mat.define-light-theme((
	color: (
		primary: $fabric-primary,
		accent: $fabric-accent,
		warn: $fabric-warn,
	)
));

@include mat.all-component-themes($fabric-theme);

@tailwind base;
@tailwind components;
@tailwind utilities;

.fb-disable-scrollbar {
	scrollbar-width: none;
	-ms-overflow-style: none;
}

.fb-disable-scrollbar::-webkit-scrollbar {
	width: 0;
	background: transparent;
	display: none;
}

.fb-blog-content {

	h2, h3, h4, h5 {
		padding-top: 5px !important;
		padding-bottom: 5px !important;
		font-weight: bold !important;
	}
}
