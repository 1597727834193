:root {
	--main-bg-color: coral;
	--fb-primary: '#9c8a6c';
	--fb-secondary: '#b7a98f';
	--fb-accent: '#EFEEE9';
}


.bmx-hide-xs {
	@media screen and (max-width: 599px) {
		display: none;
	}
}

.bmx-hide-sm {
	@media screen and (min-width: 599px) and (max-width: 959px) {
		display: none;
	}
}

.bmx-hide-md {
	@media screen and (min-width: 959px) and (max-width: 1279px) {
		display: none;
	}
}

.bmx-hide-lg {
	@media screen and (min-width: 1279px) and (max-width: 1919px) {
		display: none;
	}
}

.bmx-hide-xl {
	@media screen and (min-width: 1919px) and (max-width: 5000px) {
		display: none;
	}
}
